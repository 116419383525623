<template>
  <f7-page class="profile-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <ProfileNavigationComponent type="back" :title="pageTitle" :settings="false" />

      <f7-fab position="center-bottom" :href="'/post/create/' + (postMode == 'selftastingrecord' ? '?mode=tastingrecord' : '')">
        <font-awesome-icon :icon="['far', 'plus']" fixed-width />
      </f7-fab>
    </template>

    <section class="profile-container">
      <div class="container">
        <div class="profile-content-container">
          <PostListComponent v-if="postMode" ref="postListComponent" :mode="postMode" :size="12" />
        </div>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, inject, defineAsyncComponent, computed } from 'vue'

import { useStore } from '@/store'

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";
// import PostListComponent from "@/components/PostListComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/ProfileNavigationComponent.vue'))
const PostListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "post-list" */ /* webpackMode: "lazy" */ '@/components/PostListComponent.vue'))

export default defineComponent({
  name: 'ProfilePostPage',
  components: {
    ProfileNavigationComponent,
    PostListComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')

    const store = useStore()

    const postListComponent = ref(false)
    const showPreloader = ref(false)

    const pageTitle = ref('')
    const postMode = ref(false)

    return {
      pageTitle,
      postMode,
      postListComponent,
      showPreloader
    }
  },
  async mounted() {
    if (this?.f7route?.query?.mode === 'tastingrecord') {
      this.postMode = 'selftastingrecord'
      this.pageTitle = this.$t.getTranslation('LBL_TASTING_RECORD')
      // await this.$refs.postListComponent.reInitPostList(this.postMode);
    } else {
      this.postMode = 'self'
      this.pageTitle = this.$t.getTranslation('LBL_POST')
    }
  },
  methods: {
    async loadMore() {
      this.showPreloader = true
      if (this?.$refs?.postListComponent) await this.$refs.postListComponent.loadMore()
      this.showPreloader = false
    }
  }
})
</script>
